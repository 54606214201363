import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, validate } from "class-validator";

import { ErrorCode, ErrorMessage } from "@/constant";
import { AccountModel } from "@/models";
import { APLightbox } from "@/components";
import { Cookie } from "@/util";

export class LoginForm {
    @IsDefined() @IsNotEmpty({ message: "*必填" }) account = '';
    @IsDefined() verification = '';
}

@Component<ApTeacherLoginPasswordController>({
    components: {
        APLightbox
    }
})
export default class ApTeacherLoginPasswordController extends Vue {
    private doubleClick = false;
    private loginForm = new LoginForm();
    private verificationCode: { [key: string]: string } = {
        uid: "",
        imgSrc: ""
    };
    private errorMsgMap: { [key: string]: string } = {
        account: '',
        verification: '',
    };
    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    public created() {
        this.refreshVerificationCode(); //取得驗證碼圖片
    }

    private async refreshVerificationCode() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const timestamp = Math.floor(Date.now() / 1000).toString();
            const random = Math.floor(Math.random() * 1000).toString();
            this.verificationCode.uid = timestamp + random;

            const codeItem = {
                uniqueID: this.verificationCode.uid
            };
            this.verificationCode.imgSrc = await AccountModel.refreshCodeImg(codeItem); //通過model call api回傳所需資料
            this.doubleClick = false;
        }
    }

    private async validateAndSubmit() {
        validate(this.loginForm, { skipMissingProperties: true }).then(
            async result => {
                this.errorMsgMap = { //先清空error message
                    account: '',
                    verification: '',
                };

                if (result.length !== 0) {
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const codeItem = {
                        code: this.loginForm.verification,
                        uniqueID: this.verificationCode.uid
                    };

                    // const verificationCodeErrNo = await AccountModel.verificationCodeCheck(codeItem); //呼叫api 驗證驗證碼正確性

                    // if (verificationCodeErrNo !== ErrorCode.Success) {
                    //     this.errorMsgMap['verification'] = ErrorMessage[verificationCodeErrNo];
                    //     this.doubleClick = false;
                    //     this.refreshVerificationCode();
                    //     return;
                    // }

                    const item = {
                        acc: this.loginForm.account,
                    };
                    const errNo = await AccountModel.teForgetPwd(item);
                    if (errNo === ErrorCode.Success) {
                        this.lightbox.errorMsg = '已發送重設密碼信件，請前往電子信箱查看';
                        // 連結30分鐘有效
                        Cookie.setCookie("resetPwd", this.loginForm.account, '/teacher');
                        this.lightbox.showLB = true;
                    } else {
                        this.lightbox.errorMsg = ErrorMessage[errNo];
                        this.lightbox.showLB = true;
                        this.refreshVerificationCode();
                    }
                    this.doubleClick = false;
                }
            }
        );
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}